
.splash-screen {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    z-index: 3; /* Coloca el div detrás de la imagen de fondo */
    position: relative;
  }

  .full-screen-image {
    width: 100%;
    height: 100%;
    background-size: cover; /* Escala la imagen para cubrir todo el contenedor */
    animation: fadeOut 4s ease-in-out;
    z-index: -1;
  }

  .img-splash-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Coloca el div por encima de la imagen de fondo */
}

.img-splash-container2{
  position: absolute;
  /* width: 100%; */
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Coloca el div por encima de la imagen de fondo */
}

  .img-splash {
    max-width: 80%; /* Ajusta el tamaño máximo de la imagen */
    width: 35%;
    height: 60%;
    display: block;
    transition: transform 0.3s ease; /* Agrega una transición para el efecto de zoom */
  }

  .img-splash2 {
    max-width: 60%; /* Ajusta el tamaño máximo de la imagen */
    width: 40%;
    height: 50%;
    display: block;
    transition: transform 0.3s ease; /* Agrega una transición para el efecto de zoom */
  }

  .mobile-height img {
    height: 140px; /* Altura deseada para móviles */
    margin-bottom: 70px;
  }

  .mobile-width img {
    margin-top: -40%;
    width: 140%; 
    max-width: 180%; 
  }

  @keyframes fadeOut {
    0% {
      opacity: 4;
    }
    100% {
      opacity: 0;
    }
  }

  @media (max-width: 768px) {
    .full-screen-image {
      background-image: url('../../imgs/aqp-night.png');; /* Recorta verticalmente, ajusta horizontalmente */
    }
  }