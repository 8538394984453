
.popup {
    position: fixed !important;
    top: 40 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.2) !important;
  
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  
  .popup-inner {
    position: relative !important;
    padding: 100px !important;
    width: 580px !important;
    height: 180px !important;
    background-color: #FFF !important;
    color: black !important;
  }
  
  .popuptextcontainer{
    margin-top: -150px !important;
  }
  
  .popup-inner h3{
    color: black !important ;
    padding-top: 0% !important;
    font-weight: 600 !important;
  }
  
  .popup-inner .close-btn {
    position: absolute !important;
    top: 16px !important;
    right: 16px !important;
    color: rgb(255, 255, 255) !important;
    background-color: crimson !important;
  }
  
  