/* .card {
    perspective: 1000px;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .card-inner {
    background-color: #F6F6F6;
    position: relative;
    width: 60%;
    height: 40%;
    text-align: center;
    transform-style: preserve-3d;
    transition: transform 0.8s;
    transform: rotateY(0deg); 
  }
 
  .card-front {
    position: absolute;
    width: 50%;
    height: 50%;
    backface-visibility: hidden;
  }
  
  .card-front {
    z-index: 2;
  }
  

  
  .card img {
    margin-top: 10px;
    max-width: 60%;
    max-height: 60%;
    box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.5);
  }

  .card-back img {
    max-width: 100%;
    max-height: 100%;
  }
  
  .card h3 {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(110, 110, 110);
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }

  .card p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }  */


  .Card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around; /* Ajusta la distribución */
    padding: 20px;
    gap: 20px;
  }
  
  /* Estilos para la tarjeta de cliente */
  .card-inner {
    position: relative;
    width: 200px; /* Ajusta el ancho según tu diseño */
    height: 200px; /* Ajusta el alto según tu diseño */
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  /* Estilos para la imagen del cliente */
  .card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  
  /* Estilos para el efecto de hover */
  .card-inner:hover {
    transform: scale(1.05); /* Ajusta la escala del hover según tu preferencia */
  }
  
  /* Opcional: Estilos para la superposición o información al hacer hover */
  .card-inner::before {
    content: ''; /* Crea un pseudo-elemento para la superposición */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /*background-color: rgba(0, 0, 0, 0.7);  Ajusta el color y la opacidad */
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  /* .card-inner:hover::before {
    opacity: 1;
  } */
  
  /* Opcional: Estilos para el texto o información dentro de la superposición */
  .card-inner h3 {
    position: absolute;
    bottom: 10px; /* Ajusta la distancia desde la parte inferior */
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    margin: 0;
    padding: 5px;
    width: 80%; /* Ajusta el ancho del texto si es necesario */
    font-size: 16px; /* Ajusta el tamaño del texto */
  }

  .group2 {
    width: 100%;
    display: flex;
    justify-content: space-around;
    /* gap: -130px; */
    flex-direction: row;
  }

  .Card-container > div:nth-child(-n+4) {
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    /* Estilos para centrar las últimas tres imágenes en dispositivos móviles */
    .Card-container {
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 992px) {
    .group2 {
      flex-direction: column; /* Cambia a una disposición vertical */
      align-items: center; /* Centra el contenido en la dirección transversal (horizontal) */
      margin-left: 0; /* Reinicia el margen izquierdo */
      margin-right: 0; /* Reinicia el margen derecho */
    }
  
    .group2 > div {
      margin-bottom: 10px; /* Ajusta el margen inferior entre las tarjetas en group2 */
    }

    .section-title{
      margin-left: 5px;
    }
  }













  /* .card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
  }
  
  .card {
    position: relative;
    width: 200px;
    height: 200px; 
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }
  
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
  }
  .card:hover {
    transform: scale(1.05); 
  }
  
 
  .card .card-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .card:hover .card-inner {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.7); 
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-align: center;
  }
  
  .card .card-inner h3,
  .card .card-inner p {
    margin: 0;
    padding: 5px;
  } */